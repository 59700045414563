<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-detalle-CsNacSolicitud"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl p-0 pt-1" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle Solicitud</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Solicitudes"
                  data-toggle="tab"
                  href="#Solicitudes"
                  >Solicitud</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Autorizaciones"
                  data-toggle="tab"
                  href="#Autorizacion"
                  >Autorizaciones</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <!-- Solicitud -->
              <div class="tab-pane" id="Solicitudes">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                      <div class="card card-info card-outline collapsed-card">
                        <div class="card-header pt-2 pb-2">
                          <h3 class="card-title">
                            <i class="fas fa-clipboard-list"></i> Datos
                            Solicitud
                          </h3>
                          <div class="card-tools">
                            <span
                              class="badge"
                              :class="
                                data_solicitud.estado == 1
                                  ? 'badge-warning'
                                  : data_solicitud.estado == 2
                                  ? 'badge-success'
                                  : data_solicitud.estado == 3
                                  ? 'badge-primary'
                                  : data_solicitud.estado == 4
                                  ? 'badge-danger'
                                  : data_solicitud.estado == 5
                                  ? 'badge-primary'
                                  : data_solicitud.estado == 6
                                  ? 'badge-success'
                                  : 'badge-danger'
                              "
                              >{{ data_solicitud.nEstado }}</span
                            >
                            <button
                              type="button"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                              class="btn btn-tool"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>N° Solicitud:</b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{ data_solicitud.id }}</span
                            >
                          </div>
                          <div class="row">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Fecha:</b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{ data_solicitud.fecha }}</span
                            >
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.tipo_transporte !== 1"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Tipo De Solicitud:</b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              v-if="data_solicitud.tipoSolicitud"
                              >{{ data_solicitud.tipoSolicitud }}</span
                            >
                          </div>
                          <div class="row" v-if="data_solicitud.gerente_area">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Gerente Area: </b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{
                                data_solicitud.gerente_area
                                  ? data_solicitud.gerente_area.nombres
                                  : ""
                              }}</span
                            >
                          </div>
                          <div class="row">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Justificación: </b></label
                            >

                            <span class="col-md-8 col-sm-8 col-xs-6 text-right"
                              ><button
                                type="button"
                                class="btn bg-gradient-danger btn-xs"
                                data-toggle="modal"
                                data-target="#ModalJustificacion"
                              >
                                <i class="fas fa-file-signature"></i></button
                            ></span>
                          </div>
                          <div class="row">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Observaciones: </b></label
                            >
                            <span class="col-md-8 col-sm-8 col-xs-6 text-right"
                              ><button
                                type="button"
                                class="btn bg-gradient-warning btn-xs"
                                data-toggle="modal"
                                data-target="#ModalObservacion"
                              >
                                <i class="fas fa-file-signature"></i></button
                            ></span>
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.tipo_solicitud == 2"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Tipo De Emergencia: </b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{ data_solicitud.tipoEmergencia }}</span
                            >
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.user != undefined"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Usuario: </b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{ data_solicitud.user.name }}</span
                            >
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.user != undefined"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Correo: </b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{ data_solicitud.user.email }}</span
                            >
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.user != undefined"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Celular</b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{ data_solicitud.user.cel }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Datos Contables -->
                    <div class="col-md-6 col-sm-6 col-xs-6">
                      <div class="card card-info card-outline collapsed-card">
                        <div class="card-header pt-2 pb-2">
                          <h3 class="card-title">
                            <i class="fas fa-chart-line"></i> Datos contables
                          </h3>
                          <div class="card-tools">
                            <button
                              type="button"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                              class="btn btn-tool"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body">
                          <table
                            class="table table-bordered table-striped table-hover table-responsive-md"
                          >
                            <thead>
                              <tr>
                                <th class="text-center">Area</th>
                                <th class="text-center">OPEX/CAPEX</th>
                                <th class="text-center">Dueño AFE</th>
                                <th class="text-center">Tipo Presupuesto</th>
                                <th class="text-center">CECO/AFE/G&A</th>
                                <th class="text-center">Porcentaje</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="detCont in data_solicitud.presupuestos"
                                :key="detCont.id"
                              >
                                <td class="text-center">
                                  <span>
                                    {{ detCont.nArea }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <span>
                                    {{ detCont.opexCapex }}
                                  </span>
                                </td>

                                <td class="text-center">
                                  {{
                                    detCont.responsable
                                      ? detCont.responsable.name
                                      : ""
                                  }}
                                </td>
                                <td class="text-center">
                                  <span>
                                    {{ detCont.tipoPresupuesto }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <span>
                                    {{ detCont.ceco_afe.codigo_contable }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  {{ detCont.porcentaje }}%
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div
                        class="card card-info card-outline collapsed-card"
                        v-for="serv in data_solicitud.tipos_servicios"
                        :key="serv.id"
                      >
                        <div class="card-header pt-2 pb-2">
                          <h3 class="card-title">
                            <i class="fas fa-asterisk"></i>
                            <span v-if="serv.tipo_servicio == 1">
                              {{ serv.tipo_vehiculo.nombre }}
                              {{
                                serv.tipoRemolque
                                  ? " - " + serv.tipoRemolque
                                  : ""
                              }}
                            </span>
                            <span v-if="serv.tipo_servicio == 2">
                              {{ serv.nTipoEquipo }}
                            </span>
                            <span v-if="serv.tipo_servicio == 3">
                              {{ serv.tipoComplemen }}
                            </span>
                          </h3>
                          <div class="card-tools">
                            <button
                              type="button"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                              class="btn btn-tool"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <!-- Datos Ruta -->
                            <div class="col-md-12 col-sm-12 col-xs-12">
                              <div class="card">
                                <div class="card-header pt-2 pb-2">
                                  <h3 class="card-title">
                                    <i class="fas fa-route"></i> Detalle Ruta
                                  </h3>
                                </div>
                                <div class="card-body">
                                  <table
                                    class="table table-bordered table-striped table-hover table-sm "
                                  >
                                    <thead>
                                      <tr>
                                        <th class="text-center">Sitio</th>
                                        <th class="text-center">
                                          Nombre De Contacto
                                        </th>
                                        <th class="text-center">Teléfono</th>
                                        <th class="text-center">Correo</th>
                                        <th class="text-center">Dirección</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="detRuta in serv.ruta"
                                        :key="detRuta.id"
                                      >
                                        <td class="text-center">
                                          <span
                                            class="col-sm-6 description-header"
                                            v-if="detRuta.nombre"
                                          >
                                            {{ detRuta.nombre }}
                                          </span>
                                        </td>
                                        <td class="text-center">
                                          <span
                                            class="col-sm-6 description-header"
                                            v-if="detRuta.nombre_contacto"
                                          >
                                            {{ detRuta.nombre_contacto }}
                                          </span>
                                        </td>
                                        <td class="text-center">
                                          <span
                                            class="col-sm-6 description-header"
                                            v-if="detRuta.telefono_contacto"
                                          >
                                            {{
                                              detRuta.telefono_contacto
                                            }}</span
                                          >
                                        </td>
                                        <td class="text-center">
                                          <span
                                            class="col-sm-6 description-header"
                                            v-if="detRuta.email_contacto"
                                          >
                                            {{ detRuta.email_contacto }}
                                          </span>
                                        </td>
                                        <td class="text-center">
                                          <span
                                            class="col-sm-6 description-header"
                                            v-if="detRuta.direccion"
                                          >
                                            {{ detRuta.direccion }}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <!-- Datos Vehículo / Equipo-->
                            <div class="col-md-12 col-sm-12 col-xs-12">
                              <div class="card">
                                <div class="card-header pt-2 pb-2">
                                  <h3 class="card-title">
                                    <i class="fas fa-truck"></i> Datos
                                    Vehículo/Equipo
                                  </h3>
                                </div>
                                <div class="card-body">
                                  <table
                                    class="table table-sm table-bordered table-striped table-hover"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Tipo</th>
                                        <th class="text-center">
                                          Vehículo/Equipo
                                        </th>
                                        <th class="text-center">
                                          Cantidad Vehículos Solicitados
                                        </th>
                                        <th class="text-center">
                                          Tiempo Requerido del Servicio (Dias)
                                        </th>
                                        <th class="text-center">
                                          Transporte material
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {{ serv.tipoServicio }}
                                        </td>
                                        <td class="text-center">
                                          <span v-if="serv.tipo_servicio == 1">
                                            {{ serv.tipo_vehiculo.nombre }}
                                            {{
                                              serv.tipoRemolque
                                                ? " - " + serv.tipoRemolque
                                                : ""
                                            }}
                                          </span>
                                          <span v-if="serv.tipo_servicio == 2">
                                            {{ serv.nTipoEquipo }}
                                          </span>
                                          <span v-if="serv.tipo_servicio == 3">
                                            {{ serv.tipoComplemen }}
                                          </span>
                                        </td>
                                        <td class="text-center">
                                          {{
                                            data_solicitud.tipos_servicios
                                              ? serv.cantidad_vehiculos
                                              : ""
                                          }}
                                        </td>
                                        <td class="text-center">
                                          {{ serv.cantidad }}
                                        </td>

                                        <td>
                                          <div
                                            v-if="
                                              serv.material_inventarios == 1
                                            "
                                          >
                                            <span class="badge badge-success"
                                              >Si</span
                                            >
                                          </div>
                                          <div v-else>
                                            <span class="badge badge-danger"
                                              >No</span
                                            >
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <!-- Detalle Carga-->
                            <div class="col-md-12 col-sm-12 col-xs-12">
                              <div class="card">
                                <div class="card-header pt-2 pb-2">
                                  <h3 class="card-title">
                                    <i class="fas fa-truck-loading"></i> Detalle
                                    Carga
                                  </h3>
                                </div>
                                <div class="card-body">
                                  <table
                                    class="table table-bordered table-striped table-hover table-sm table-responsive"
                                  >
                                    <thead>
                                      <tr>
                                        <th class="text-center">
                                          # Pedido Compra de Material
                                        </th>
                                        <th class="text-center">
                                          Pos. en el Pedido
                                        </th>
                                        <th class="text-center">
                                          Descripción del material o equipos
                                        </th>
                                        <th class="text-center">
                                          Largo x Ancho x Alto (MTS)
                                        </th>
                                        <th class="text-center">
                                          Peso(Kg)
                                        </th>
                                        <th class="text-center">
                                          Valor del Material
                                        </th>
                                        <th class="text-center">
                                          Moneda Área
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="det in serv.det_carga"
                                        :key="det.id"
                                      >
                                        <td class="text-center">
                                          {{ det.no_pedido_compra }}
                                        </td>
                                        <td class="text-center">
                                          {{ det.pos_pedido }}
                                        </td>
                                        <td class="text-center">
                                          {{ det.descripcion_material }}
                                        </td>
                                        <td class="text-center">
                                          {{ det.largo }} x {{ det.ancho }} x
                                          {{ det.alto }}
                                        </td>
                                        <td class="text-center">
                                          {{ det.peso }}
                                        </td>
                                        <td class="text-center">
                                          {{ det.valor }}
                                        </td>
                                        <td class="text-center">
                                          {{ det.nMoneda }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Autorización -->
              <div class="tab-pane" id="Autorizacion">
                <div
                  class="card"
                  v-if="data_solicitud.autorizacion != undefined"
                >
                  <div
                    class="card-body"
                    v-if="data_solicitud.autorizacion.length > 0"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          class="table table-sm table-bordered table-hover table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Tipo</th>
                              <th>Funcionario</th>
                              <th>Funcionario Que Autoriza</th>
                              <th>Fecha Autorizo</th>
                              <th>Estado</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="autor in data_solicitud.autorizacion"
                              :key="autor.id"
                            >
                              <td>
                                {{ autor.tipoAutorizacion }}
                              </td>
                              <td>
                                {{ autor.user ? autor.user.name : "" }}
                              </td>
                              <td v-if="autor.user_autoriza">
                                {{ autor.user_autoriza.name }}
                              </td>
                              <td v-else></td>
                              <td>{{ autor.fecha_autorizacion }}</td>
                              <td>
                                <span
                                  class="badge"
                                  :class="
                                    data_solicitud.nEstado == 1
                                      ? 'badge-success'
                                      : 'badge-danger'
                                  "
                                  >{{ autor.nEstado }}</span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-body" v-else>
                    <div class="info-box bg-gradient-warning">
                      <span class="info-box-icon"
                        ><i class="fas fa-exclamation-triangle"></i
                      ></span>
                      <div class="info-box-content">
                        <span class="info-box-text"
                          >No tiene Autorizaciones</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Justificación-->
    <div
      class="modal fade"
      id="ModalJustificacion"
      tabindex="-1"
      role="dialog"
      style="background-color: #00000082; z-index: 20000 !important"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h5 class="modal-title text-white">
              Justificación
            </h5>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ data_solicitud.justificacion }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Observación -->
    <div
      class="modal fade"
      id="ModalObservacion"
      tabindex="-1"
      role="dialog"
      style="background-color: #00000082; z-index: 20000 !important"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h5 class="modal-title text-white">
              Observación
            </h5>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ data_solicitud.observaciones }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "CsSolicitudesNacDetalle",
  components: {},
  data() {
    return {
      data_solicitud: {},
    };
  },
  methods: {
    async llenar_modal(solicitud) {
      this.data_solicitud = solicitud;
      $(".nav-link").removeClass("active");
      $("#tab-Solicitudes").addClass("active");
      $(".tab-pane").removeClass("active");
      $("#Solicitudes").addClass("active");
    },
  },
  async mounted() {},
};
</script>
